/* tslint:disable:no-console */

import localforage from 'localforage';
import store from './store';

// Check that service workers are registered
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register(`${process.env.BASE_URL}${process.env.VUE_APP_SW_FILE}`)
      .then((registration) => {
        store.commit('ui/setSwRegistration', {
          reg: registration,
        });

        // Periodically check for updates
        const sixHours = 1000 * 60 * 60 * 6;
        setInterval(() => {
          registration.update().catch(() => {
            console.error('Could not update service worker, probably offline.');
          });
        }, sixHours);

        if (
          navigator
          && navigator.serviceWorker
          && navigator.serviceWorker.controller
        ) {
          navigator.serviceWorker.controller.postMessage({
            type: 'env',
            data: process.env,
          });

          // If we are authed let the service worker know
          const vuexJSON = localStorage.getItem('vuex');
          if (vuexJSON) {
            const vuex = JSON.parse(vuexJSON);
            if (vuex && vuex.user && vuex.user.token) {
              const { token } = vuex.user;
              navigator.serviceWorker.controller.postMessage({
                type: 'auth',
                data: token,
              });
            }
          }

          navigator.serviceWorker.addEventListener('message', (event) => {
            if (event.data.type === 'restore') {
              localforage.getItem('vuex', (err, vuex: any) => {
                if (err) {
                  return;
                }
                store.commit('restoreSubmissions', {
                  submissions: vuex.submissions.submissions,
                });
              });
            }
          });

          // When a new service worker is installed we should reload the page
          let refreshing;
          navigator.serviceWorker.addEventListener('controllerchange',
            (e) => {
              if (refreshing) return;
              refreshing = true;
              window.location.reload();
            });
        }

        listenForWaitingServiceWorker(registration, () => {
          store.commit('ui/setUpdateAvailable', {
            updateAvailable: true,
          });
        });
      })
      .catch((error) => {
        console.error(error);
        console.error('Could not register service worker 😭');
      });
  });
}

// See https://redfin.engineering/how-to-fix-the-refresh-button-when-using-service-workers-a8e27af6df68
function listenForWaitingServiceWorker(reg, callback) {
  function awaitStateChange() {
    reg.installing.addEventListener('statechange', (e: any) => {
      if (e.target.state === 'installed') callback(reg);
    });
  }
  if (!reg) return;
  if (reg.waiting) return callback(reg);
  if (reg.installing) awaitStateChange();
  reg.addEventListener('updatefound', awaitStateChange);
}
