import { FormSubmission } from '@stratumfive/snapshot-forms/src/forms/types/FormDefinition';
import { Submission } from '@/rxdb/modules/submissions.module';
import { Form } from '@/shared/types';
import { getLatestForms } from '@/store/modules/forms';
import isValidSubmissionOrder, { canValidateSubmissionType, SubmissionType } from '@/workflow/submissionType';
import dayjs from 'dayjs';

export const LOCATION_FIELD_TITLE = 'Location';

/*
 * Helper function to narrow down the type of the given submission inside getPositionSubmissionSubtype.
 * If the submission type is FormSubmission it will return true.
 * If this function returns false the submission is therefore a Submission, which TS will infer.
 */
const isFormSubmission = (
  submission: Submission | FormSubmission,
): submission is FormSubmission => (submission as FormSubmission).form !== undefined;

/*
 * Returns the sub-type of a Position submission. Although the top level submission is simply considered to
 * be a "Position" submission, in reality whether it's "At Sea", "Drifting" or "In Port" is of equal
 * significance as any other top level submission distinction.
 */
const getPositionSubmissionSubtype = async (submission: Submission | FormSubmission): Promise<string> => {
  const forms = await getLatestForms();

  let submissionForm: Form | undefined;

  if (isFormSubmission(submission)) {
    submissionForm = forms.find(({ id }) => id === submission.form.id);
  } else {
    submissionForm = forms.find(({ id }) => id === submission.formId);
  }

  const locationField = submissionForm?.fields.find(({ title }) => title === LOCATION_FIELD_TITLE);

  /* The location field should always exist, but if not
   * this will simply return SubmissionType.Position
   */
  if (!locationField) {
    return submission.type!;
  }

  const { id: locationFieldId } = locationField;

  return submission.content[locationFieldId];
};

/*
 * Returns the type of the given submission. If the submission is a Position submission,
 * it will drill down into the location field to determine the sub-type, and return that.
 */
const getSubmissionType = async (submission: Submission | FormSubmission): Promise<string> => (
  submission.type === SubmissionType.Position
    ? getPositionSubmissionSubtype(submission)
    : submission.type!
);

/*
 * Determines whether the overall workflow of previous submission => current submission is valid.
 * Returns a boolean for the validity, as well as the previous submission type to allow more verbose UI output
 * if the workflow is invalid.
 */
const isSubmissionWorkflowValid = async (
  currentSubmission: FormSubmission,
  previousSubmissions: Submission[],
): Promise<{
  previousSubmissionType: string,
  isValidSubmissionOrder: boolean,
}> => {
  const currentSubmissionType = await getSubmissionType(currentSubmission);

  // If the current report is not a timeline report, e.g. Cargo Handling, simply skip validation
  if (!canValidateSubmissionType(currentSubmissionType)) {
    return {
      isValidSubmissionOrder: true,
      previousSubmissionType: '',
    };
  }

  // Find the first submission prior to this one with a type which can be validated against
  const previousSubmission = previousSubmissions.find(({ reportDate, type }) => (
    dayjs(reportDate).isBefore(dayjs(currentSubmission.reportDate))
    && canValidateSubmissionType(type)));

  // If there are no previous reports requiring validation, simply return true
  if (!previousSubmission) {
    return {
      isValidSubmissionOrder: true,
      previousSubmissionType: '',
    };
  }

  const previousSubmissionType = await getSubmissionType(previousSubmission);

  if (isValidSubmissionOrder(currentSubmissionType, previousSubmissionType)) {
    return {
      isValidSubmissionOrder: true,
      previousSubmissionType,
    };
  }

  return {
    isValidSubmissionOrder: false,
    previousSubmissionType,
  };
};

export default isSubmissionWorkflowValid;
