/* eslint-disable no-shadow */
import { API } from '@/API';
import { getDatabase } from '@/rxdb/Database.service';

export const REPORT_DATE_GLOBALS_NAMES = [
  'reportDate',
  'startOfSeaPassage',
  'endOfSeaPassage',
  'delayStartTime',
];

/**
 * Shared globals are used to identify form inputs as specific known values
 * that we need to track such as 'Report Date', 'LFO fuel ROB' etc
 * This store will handle loading them and storing them
 */
const state = {
  sharedGlobals: [],
  loading: false,
};

export const getters = {
  map(state) {
    return state.sharedGlobals.reduce((map, sharedGlobal) => {
      map[sharedGlobal.id] = sharedGlobal;
      return map;
    }, {});
  },
  byName(state) {
    return state.sharedGlobals.reduce((map, sharedGlobal) => {
      map[sharedGlobal.name] = sharedGlobal;
      return map;
    }, {});
  },
  reportDateGlobals(state, getters) {
    return REPORT_DATE_GLOBALS_NAMES.map((name) => getters.byName[name]).filter(
      (global) => global,
    );
  },
};

export const mutations = {
  setSharedGlobals(state, { sharedGlobals }) {
    state.sharedGlobals = Object.freeze(sharedGlobals);
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    state.sharedGlobals = [];
    state.loading = false;
  },
};

export const actions = {
  async init({ dispatch }) {
    // Load up the form schemas from RxDB and listen for changes
    const db = await getDatabase();
    db.sharedglobals.$.subscribe(() => {
      dispatch('load');
    });

    await dispatch('load');
  },
  async load({ commit }) {
    const db = await getDatabase();
    const sharedGlobals = await db.sharedglobals
      .find()
      .exec();

    commit('setSharedGlobals', { sharedGlobals: Object.freeze(sharedGlobals.map(global => global.toJSON())) });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
