/* eslint-disable no-shadow */
import * as Sentry from '@sentry/browser';
import { API, setHeader } from '@/API';
import EventBus from '@/EventBus';
import {
  USER_STORAGE_KEY, TOKEN_STORAGE_KEY, USER_LOGIN_EVENT, USER_LOGOUT_EVENT, VESSEL_STORAGE_KEY, USER_AUTHOR_NAME_KEY,
} from '../helpers/user-constants';

let existingUser;
try {
  const userString = localStorage.getItem(USER_STORAGE_KEY);
  if (userString) {
    existingUser = JSON.parse(userString);
  }
} catch {
  console.error('No logged in user');
}

let existingVessel;
try {
  const vesselString = localStorage.getItem(VESSEL_STORAGE_KEY);
  if (vesselString) {
    existingVessel = JSON.parse(vesselString);
  }
} catch {
  console.error('No existing vessel');
}

const state = {
  user: existingUser || null,
  vessel: existingVessel,
  token: localStorage.getItem(TOKEN_STORAGE_KEY),
  error: '',
  authorName: localStorage.getItem(USER_AUTHOR_NAME_KEY),
};

export const getters = {
  getUser: (state) => state.user,
  getToken: (state) => state.token,
};

export const mutations = {
  setUser: (state, { user }) => {
    state.user = user;
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
  },
  setVessel: (state, { vessel }) => {
    state.vessel = vessel;
    localStorage.setItem(VESSEL_STORAGE_KEY, JSON.stringify(vessel));
  },
  setToken: (state, { token }) => {
    state.token = token;
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
  },
  setUserErrors: (state, { error }) => {
    state.error = error;
  },
  setAuthorName: (state, { authorName }) => {
    state.authorName = authorName;
    localStorage.setItem(USER_AUTHOR_NAME_KEY, authorName);
  },
};

export const actions = {
  logout({ commit, dispatch }) {
    commit('setUser', { user: {} });
    commit('setVessel', { vessel: null });
    commit('setToken', { token: '' });
    commit('setUserErrors', { error: {} });
    commit('setAuthorName', { authorName: '' });

    // TODO Move this out of here too, shouldn't be the responsibility of the user store
    if (
      navigator
      && navigator.serviceWorker
      && navigator.serviceWorker.controller
    ) {
      navigator.serviceWorker.controller.postMessage({
        type: 'auth',
        data: null,
      });
    }

    EventBus.$emit(USER_LOGOUT_EVENT);
    dispatch('shutDown', null, { root: true });
  },
  login({ commit, dispatch }, { username, password }) {
    return new Promise((resolve, reject) => {
      API.post('/auth/login', { username, password })
        .then((res: any) => {
          const { accessToken, user, vessel } = res.data;
          commit('setUser', { user });
          commit('setVessel', { vessel });
          commit('setToken', { token: accessToken });
          setHeader('Authorization', `Bearer ${accessToken}`);

          // TODO Move this out of here too, shouldn't be the responsibility of the user store
          if (
            navigator
            && navigator.serviceWorker
            && navigator.serviceWorker.controller
          ) {
            navigator.serviceWorker.controller.postMessage({
              type: 'auth',
              data: accessToken,
            });
          }

          // Identify with sentry
          // TODO Move this out of here
          Sentry.configureScope((scope) => {
            scope.setUser({
              id: user.id,
              username: user.username,
            });
          });

          EventBus.$emit(USER_LOGIN_EVENT, {
            token: accessToken,
            user,
          });

          dispatch('startUp', null, { root: true });

          resolve(res);
        })
        .catch((error) => {
          console.error('Could not login');
          commit('setUserErrors', { error: 'Could not log in' });
          reject();
        });
    });
  },
  test() {
    console.log('test');
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
