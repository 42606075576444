/* eslint-disable no-shadow */
import { Vue } from 'vue-property-decorator';

const syncDuration = localStorage.getItem('sync-duration') ? localStorage.getItem('sync-duration') : 10;

const state = {
  theme: false,
  online: false,
  storeLoaded: false,
  showErrors: false,
  menus: {},
  updateAvailable: false,
  lastUpdated: null,
  layout: '',
  swRegistration: null,
  syncDuration, // How many days of data should the UI load
};

export const getters = {
  getDisabledField: (state) => (id) => state.disabledFields[id],
  getMenu: (state) => (id) => state.menus[id],
};

export const mutations = {
  setMenuState(state, { id, value }) {
    Vue.set(state.menus, id, value);
  },
  setOnlineStatus(state, { status }) {
    state.online = status;
  },
  setUpdateAvailable(state, { updateAvailable }) {
    state.updateAvailable = updateAvailable;
  },
  setLoaded(state) {
    state.storeLoaded = true;
  },
  setShowErrors(state, { value }) {
    state.showErrors = value;
  },
  setUiLastUpdated(state, { lastUpdated }) {
    state.lastUpdated = lastUpdated;
  },
  setLayout(state, { layout }) {
    state.layout = layout;
  },
  setSwRegistration(state, { reg }) {
    state.swRegistration = reg;
  },
  setSyncDuration(state, { duration }) {
    state.syncDuration = duration;
    localStorage.setItem('sync-duration', duration);
  },
};

export const actions = {
  async installNewServiceWorker({ state }) {
    if (!state.swRegistration) {
      window.location.reload();
      return;
    }

    if (state.swRegistration && !state.swRegistration.waiting) {
      await state.swRegistration.update();
      window.location.reload();
    }

    if (state.swRegistration.waiting) {
      state.swRegistration.waiting.postMessage({ type: 'skip-waiting' });
    }
    if (state.swRegistration.installing) {
      state.swRegistration.installing.postMessage({ type: 'skip-waiting' });
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
