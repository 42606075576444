import { RxDatabase, RxJsonSchema, RxCollection } from 'rxdb';
import dayjs from 'dayjs';
import { loadForm } from '@/store/modules/forms';
import { USER_AUTHOR_NAME_KEY } from '@/store/helpers/user-constants';
import { RxModule } from './RxModule.interface';

export type Submission = {
  id: string;
  created: string;
  updated: string;
  position: number;
  // deleted: boolean;
  title: string;
  reportDate: string;
  status: string;
  content: any;
  version: number;
  vesselId: number;
  formId: string;
  formVersion: number;
  type: string;
  // customerId: string;
}

const schemaV1: RxJsonSchema<Submission> = {
  title: 'Submission Schema',
  version: 1,
  description: 'Describes a Snapshot submission',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
      default: '',
    },
    created: {
      type: 'string',
      default: '',
    },
    updated: {
      type: 'string',
      default: '',
    },
    position: {
      type: 'number',
    },
    title: {
      type: 'string',
    },
    reportDate: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    content: {
      type: 'object',
      properties: {},
      additionalProperties: true,
    },
    version: {
      type: 'number',
    },
    vesselId: {
      type: 'number',
    },
    formId: {
      type: 'string',
    },
    formVersion: {
      type: 'number',
    },
    type: {
      type: ['string', 'null'],
    },
  },
  required: ['id', 'created', 'content', 'reportDate', 'status'],
  indexes: ['reportDate', 'created', 'status', 'formId'],
};

let collection: RxCollection<Submission>;

const pullQueryBuilder = (syncDuration: number) => (doc) => {
  if (!doc) {
    // the first pull does not have a start-document
    doc = {
      position: 0,
      updated: dayjs().subtract(syncDuration, 'day').toISOString(),
    };
  }

  const query = `{
    submissionsForRxDB(
      lastPosition: "${doc.position}",
      minUpdated: "${doc.updated}"
      limit: 100,
    ) {
      id
      created
      updated
      position
      deleted
      title
      reportDate
      status
      content
      version
      vesselId
      formId
      formVersion
      type
    }
  }`;

  return {
    query,
    variables: {},
  };
};

const pushQueryBuilder = (doc) => {
  const query = `
      mutation CreateSubmission($submissionData: CreateOrUpdateSubmissionDto!) {
        submissionRxDBCreateOrUpdate(submissioData: $submissionData) {
          id
          updated
        }
      }
  `;

  const variables = {
    submissionData: doc,
  };

  return {
    query,
    variables,
  };
};

const submissionsModule: RxModule = {
  async init(db: RxDatabase) {
    collection = await db.collection({
      name: 'submissions',
      schema: schemaV1,
      migrationStrategies: {
        /**
         * We added the type property to submissions and increased the schema version from 0 to 1.
         * Here we look for the submissions form type. If we can find it we add it to the submission.
         * @param oldDoc
         */
        1: async (oldDoc: Submission) => {
          const form = await loadForm(oldDoc.formId, oldDoc.formVersion);
          oldDoc.type = form ? form.type : '';
          return oldDoc;
        },
      },
    });

    return collection;
  },
  initReplication(authToken: string, baseUrl: string, syncDuration: number) {
    const replicationState = collection.syncGraphQL({
      url: `${baseUrl}/graphql`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      pull: {
        queryBuilder: pullQueryBuilder(syncDuration),
      },
      push: {
        queryBuilder: pushQueryBuilder,
        modifier: async (doc: Submission) => {
          const submissionGlobals = await collection.database.submissionglobals.find()
            .where('submissionId')
            .eq(doc.id)
            .exec()
            .then((items) => items.map((item) => {
              const global = item.toJSON();
              delete global.created;
              delete global.updated;
              return global;
            }));

          // This fixes the "consumptionReceived" global
          // that got changed somehow to null even though the type of it is string.
          // If we don't change it back to string it will fail to create a new submission on the db.
          const nullConsumptionReceived = submissionGlobals.find(
            (global) => global.globalId === '6d538e5c-d59f-455d-aebb-97fcafca2d61' && global.value === null,
          );

          if (nullConsumptionReceived) {
            nullConsumptionReceived.value = '';
          }

          const authorName = localStorage.getItem(USER_AUTHOR_NAME_KEY);

          return {
            ...doc,
            form: {
              id: doc.formId,
              version: doc.formVersion,
            },
            vessel: {
              id: doc.vesselId,
            },
            authorName,
            globalValues: submissionGlobals,
            source: 'snapshot-ui',
          };
        },
        // batchSize: 5, // (optional) amount of documents that will be send in one batch
      },
      deletedFlag: 'deleted',
      live: true,
      liveInterval: 1000 * 15,
    });

    replicationState.error$.subscribe((error) => {
      console.error('something was wrong');
      console.dir(error);
    });

    // setup the subscription client
    // const wsClient = new SubscriptionClient(
    //   'wss://snapshot-api-dev.stratumfive.com/next/graphql', {
    //     reconnect: true,
    //   },
    // );

    // const query = `subscription onSubmissionChanged {
    //   submissionCreatedOrUpdated {
    //     id
    //   }
    // }`;

    // const changeObservable = wsClient.request({ query });

    // // subscribe to all events
    // changeObservable.subscribe({
    //   next(data) {
    //     /**
    //        * When a change happens, call .run() on the replicationState.
    //        * This will trigger the pull-handler and download changes from the server.
    //        */
    //     replicationState.run();
    //   },
    // });

    return replicationState;
  },
};

export default submissionsModule;
