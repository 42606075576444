import localForage from 'localforage';
import dayjs from 'dayjs';
import { API } from '@/API';

// Create the localForage store so we can save the forms offline
const vesselGlobalStore = localForage.createInstance({
  name: 'vessel-global-store',
});

const LOAD_INTERVAL = 5; // The amount of time we wait before reloading vessel globals in days
const VESSEL_GLOBALS_KEY = 'vesselGlobals';
const LAST_UPDATED_KEY = 'lastUpdated';

/* eslint-disable no-shadow */
const state = {
  loading: false,
  vesselGlobals: [],
  lastUpdated: null,
};

/**
 * Get the key used to store a form
 * in the form store
 * @param id The form id
 * @param version The form version
 */
function getFormKey(id: string, version: number) {
  return `${id}-${version}`;
}

export const mutations = {
  setLastUpdated(state, { lastUpdated }) {
    state.lastUpdated = lastUpdated;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  setVesselGlobals(state, { vesselGlobals }) {
    state.vesselGlobals = vesselGlobals;
  },
  reset({ state }) {
    if (state) {
      state.loading = false;
      state.vesselGlobals = false;
      state.lastUpdated = false;
    }
  },
};

export const actions = {
  async init({ commit, dispatch }, { vesselId }) {
    // Load the values we need out of the localforage store and back into state
    const lastUpdated = await vesselGlobalStore.getItem(LAST_UPDATED_KEY);
    commit('setLastUpdated', { lastUpdated });

    const vesselGlobals = await vesselGlobalStore.getItem(VESSEL_GLOBALS_KEY);
    commit('setVesselGlobals', { vesselGlobals });

    // Periodically check for new vessel globals
    dispatch('loadVesselGlobals', { vesselId });
    setInterval(() => {
      dispatch('loadVesselGlobals', { vesselId });
    }, 60 * 1000 * 10); // Check for new forms every 10 mins
  },
  async loadVesselGlobals({ state, dispatch, commit }, { vesselId }) {
    // Check to see if we need to load, if we don't return early
    if (
      state.vesselGlobals !== null
      && state.lastUpdated !== null
      && dayjs(state.lastUpdated).diff(dayjs(), 'day') < LOAD_INTERVAL) {
      return;
    }

    commit('setLoading', { loading: true });
    const vesselGlobals = await API.get(`/vessel-globals?vesselId=${vesselId}`)
      .then((res) => res.data);

    dispatch('setVesselGlobals', { vesselGlobals });
    await dispatch('setLastUpdated', { lastUpdated: dayjs().toISOString() });

    commit('setLoading', { loading: false });
  },
  async reset({ commit }) {
    commit('reset');
    await vesselGlobalStore.clear();
  },
  async setLastUpdated({ commit }, { lastUpdated }) {
    await vesselGlobalStore.setItem(LAST_UPDATED_KEY, lastUpdated);
    commit('setLastUpdated', { lastUpdated });
  },
  async setVesselGlobals({ commit }, { vesselGlobals }) {
    await vesselGlobalStore.setItem(VESSEL_GLOBALS_KEY, vesselGlobals);
    commit('setVesselGlobals', { vesselGlobals });
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
