import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import ReportContent from '@/components/report/ReportContent.vue';
import SubmissionContent from '@/components/submission/SubmissionContent.vue';
import EditSubmission from '@/components/submission/EditSubmission.vue';
import store from '@/store';

Vue.use(Router);
Vue.use(Meta);

export function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      name: 'home',
      component: loadView('Home'),
      meta: {
        authenticated: true,
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: loadView('Reports'),
      meta: {
        authenticated: true,
        breadcrumb: {
          text: 'Reports',
          link: { name: 'reports' },
        },
      },
    },
    {
      path: '/reports/:id/:version',
      component: loadView('Report'),
      props: (route) => ({
        id: route.params.id,
        version: parseInt(route.params.version, 10),
      }),
      meta: {
        authenticated: true,
        breadcrumb: {
          text: 'Reports',
          link: { name: 'reports' },
        },
      },
      children: [
        {
          path: '',
          name: 'report',
          component: ReportContent,
          props: (route) => ({
            id: route.params.id,
            version: parseInt(route.params.version, 10),
          }),
          meta: {
            authenticated: true,
            breadcrumb: (route) => {
              const report = store.getters.getReport(
                route.params.id,
                parseInt(route.params.version, 10),
              );
              return report
                ? {
                  text: report.title,
                  link: { name: 'report', params: route.params },
                }
                : null;
            },
          },
        },
        {
          path: 'submission/:submissionId',
          name: 'submission',
          component: SubmissionContent,
          props: true,
          meta: {
            authenticated: true,
            breadcrumb: (route) => {
              const report = store.getters.getReport(
                route.params.id,
                parseInt(route.params.version, 10),
              );
              return report
                ? {
                  text: report.title,
                  link: { name: 'report', params: route.params },
                }
                : null;
            },
          },
        },
        {
          path: 'submission/:submissionId/edit',
          name: 'edit-submission',
          component: EditSubmission,
          props: true,
          meta: {
            authenticated: true,
            breadcrumb: (route) => {
              const report = store.getters.getReport(
                route.params.id,
                parseInt(route.params.version, 10),
              );
              return report
                ? {
                  text: report.title,
                  link: { name: 'report', params: route.params },
                }
                : null;
            },
          },
        },
        {
          path: 'drafts/:submissionId',
          name: 'drafts',
          component: EditSubmission,
          props: true,
          meta: {
            authenticated: true,
            breadcrumb: (route) => {
              const report = store.getters.getReport(
                route.params.id,
                parseInt(route.params.version, 10),
              );
              return report
                ? {
                  text: report.title,
                  link: { name: 'report', params: route.params },
                }
                : null;
            },
          },
        },
      ],
    },
    {
      path: '/settings',
      name: 'settings',
      component: loadView('Settings'),
      meta: {
        authenticated: true,
      },
    },
    {
      path: '/guide',
      name: 'guide',
      beforeEnter() {
        window.open('https://snapshot-docs-stratumfive.netlify.com/', '_blank');
      },
      meta: {
        authenticated: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: loadView('Login'),
      meta: {
        authenticated: false,
      },
    },
    {
      path: '/system-compatibility',
      name: 'system-compatibility',
      component: loadView('SystemCompatibility'),
      meta: {
        authenticated: false,
      },
    },
    { path: '*', redirect: '/' },
  ],
});

router.beforeEach((to, from, next) => {
  if (!store.state.user.token && to.meta.authenticated) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
