import Vue from 'vue';
import { time } from 'vue-analytics';
import { combineLatest } from 'rxjs';
import { createRxDatabase, addRxPlugin, RxDatabase } from 'rxdb';
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBReplicationGraphQLPlugin, RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxModule } from './modules/RxModule.interface';
import { STATIC_API_URL } from '../API';

// Modules
import submissionsModule from './modules/submissions.module';
import formSchemasModule from './modules/form-schemas.module';
import submissionGlobalsModule from './modules/submission-globals.module';
import submissionVersionsModule from './modules/submission-versions.module';
import sharedGlobalsModule from './modules/shared-globals.module';
import consumptionConfigModule from './modules/consumption-config.module';

addRxPlugin(RxDBValidatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBReplicationGraphQLPlugin);
addRxPlugin(RxDBJsonDumpPlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(require('pouchdb-adapter-idb'));

let db: RxDatabase | null;
let dbResolove;
let dbPromise: Promise<RxDatabase> = new Promise((resolve) => { dbResolove = resolve; });
let replicationStates: RxGraphQLReplicationState[];

const REPLICATION_STATE_KEY = 'REPLICATION_STATE_KEY';
let inititialReplicationResolve;
const initialReplicationPromise = new Promise((resolve) => { inititialReplicationResolve = resolve; });
const initialReplicationCompletedOnce = localStorage.getItem(REPLICATION_STATE_KEY);
const replicationInfo = Vue.observable({
  loading: false,
  initialProgess: initialReplicationCompletedOnce ? 100 : 0,
});

const rxModules: RxModule[] = [
  submissionsModule,
  formSchemasModule,
  submissionGlobalsModule,
  submissionVersionsModule,
  sharedGlobalsModule,
  consumptionConfigModule,
];

/**
 * Initialise the rxdb database
 */
export async function init(authToken: string, syncDuration: number) {
  if (db) {
    throw new Error('RxDB already exists');
  }

  db = await createRxDatabase({
    name: 'snapshot',
    adapter: 'idb',
  });

  await Promise.all(rxModules.map((rxModule) => rxModule.init(db!)));

  // eslint-disable-next-line dot-notation
  window['db'] = db;

  await initReplication(authToken, syncDuration);

  dbResolove(db);
  return db;
}

/**
 * Get the RxDB database
 */
export async function getDatabase(): Promise<RxDatabase> {
  return dbPromise;
}

/**
 * Get the RxDB database
 */
export function getReplicationState(collectionName: string) {
  return replicationStates.find((replicationState) => replicationState.collection.name === collectionName);
}

/**
 * Init RxDB replication with the graphql server
 * @param authToken string
 * @param syncDuration number The number of days of histric data to sync
 */
export async function initReplication(authToken: string, syncDuration: number) {
  const startTime = new Date().getDate();

  // If the hostname includes the word static we know that Snapshot is being loaded using
  // the static IP version. Some vessels can only whitelist Snapshot by a static IP address.
  // If the UI is loaded over static IP we also need to make sure it uses a backend endpoint
  // With a static IP
  const needsStaticIp = window.location.hostname.includes('snapshot-static.stratumfive.com');

  // Check the primary endpoint url and the backup endpoint url. If the primary
  // url doesn't work we try the secondary. This helps us to get around DNS caching
  // issues
  const baseUrl = !needsStaticIp ? process.env.VUE_APP_API_URL : STATIC_API_URL;
  replicationStates = rxModules.map((rxModule) => rxModule.initReplication(authToken, baseUrl!, syncDuration));

  let numberCompleted = 0;
  const replicating = replicationStates
    .map((state) => state.awaitInitialReplication().then(() => {
      numberCompleted += 1;
      replicationInfo.initialProgess = replicationInfo.initialProgess === 100
        ? 100 : (numberCompleted / replicationStates.length) * 100;
      return state.collection.name;
    }));

  const replicationActive = combineLatest(replicationStates.map((state) => state.active$));
  replicationActive.subscribe((values) => {
    replicationInfo.loading = values.some((v) => v);
  });

  // If we have already done the intital replication replication once after logging in
  // in we resolve instantly, otherwise we should wait for everything to finish
  if (!initialReplicationCompletedOnce) {
    await Promise.all(replicating);
    const endTime = new Date().getTime();
    const duration = endTime - startTime;
    time('rxdb', 'replication', duration, 'finished');
    localStorage.setItem(REPLICATION_STATE_KEY, 'true');
  }
}

/**
 * Return the information about the replication status
 */
export function getReplicationInfo() {
  return replicationInfo;
}

/**
 * Remove the database and all of its data
 */
export async function removeDatabase() {
  if (!db) {
    throw new Error('RxDb not initialised');
  }

  await db.remove();

  replicationInfo.loading = false;
  replicationInfo.initialProgess = 0;
  localStorage.removeItem(REPLICATION_STATE_KEY);

  db = null;
  dbPromise = new Promise((resolve) => { dbResolove = resolve; });
}
