import { RxDatabase, RxJsonSchema, RxCollection } from 'rxdb';
import dayjs from 'dayjs';
import { RxModule } from './RxModule.interface';

export type SubmissionVersion = {
  id: number;
  created: string;
  updated: string;
  content: any;
  submissionId: string;
  authorName: string;
  version: number;
}

const schemaV1: RxJsonSchema<SubmissionVersion> = {
  title: 'Submission Version',
  version: 0,
  description: 'Describes a Snapshot Submission Version',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    created: {
      type: 'string',
      default: '',
    },
    updated: {
      type: 'string',
      default: '',
    },
    content: {
      type: 'object',
    },
    submissionId: {
      type: 'string',
    },
    version: {
      type: 'number',
    },
    authorName: {
      type: 'string',
    },
  },
  indexes: ['submissionId'],
};

let collection: RxCollection<SubmissionVersion>;

const pullQueryBuilder = (syncDuration: number) => (doc) => {
  if (!doc) {
    // the first pull does not have a start-document
    doc = {
      id: '',
      updated: dayjs().subtract(syncDuration, 'day').toISOString(),
    };
  }

  const query = `{
          submissionVersionsForRxDB(
              lastId: "${doc.id}",
              minUpdated: "${doc.updated}"
              limit: 50,
          ) {
            id
            created
            updated
            content
            version
            authorName
            submissionId
          }
      }`;

  return {
    query,
    variables: {},
  };
};

const submissionVersionsModule: RxModule = {
  async init(db: RxDatabase) {
    collection = await db.collection({
      name: 'submissionversions', // For some reason it doesn't like it being camel case submissionVersions
      schema: schemaV1,
    });

    return collection;
  },
  initReplication(authToken: string, baseUrl: string, syncDuration: number) {
    const replicationState = collection.syncGraphQL({
      url: `${baseUrl}/graphql`, // url to the GraphQL endpoint
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      pull: {
        queryBuilder: pullQueryBuilder(syncDuration), // the queryBuilder from above
      },
      deletedFlag: 'deleted', // the flag which indicates if a pulled document is deleted
      live: true,
      liveInterval: 1000 * 60,
    });

    replicationState.error$.subscribe((error) => {
      console.error('RxDB: something went wrong getting submission versions');
      console.dir(error);
    });

    return replicationState;
  },
};

export default submissionVersionsModule;
