





























































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import SUser from '@stratumfive/ui-baseplate/src/components/SUser/SUser.vue';
import SDialog from '@stratumfive/ui-baseplate/src/components/SDialog/SDialog.vue';
import SAlert from '@stratumfive/ui-baseplate/src/components/SAlert/SAlert.vue';

@Component({
  components: {
    SUser,
    SDialog,
    SAlert,
  },
})
export default class UserOnlineStatus extends Vue {
  @Action('logout') private logout;

  @Mutation('ui/setOnlineStatus') private setOnlineStatus;

  @State((state) => state.ui.online)
  private online;

  @State((state) => state.user.user)
  private user;

  @State((state) => state.user.authorName)
  private authorName;

  private colour = 'error';

  private created() {
    window.addEventListener('offline', this.setOffline.bind(this));
    window.addEventListener('online', this.setOnline.bind(this));
    if (navigator.onLine) {
      this.setOnline();
    } else {
      this.setOffline();
    }
  }

  private destroyed() {
    window.removeEventListener('offline', this.setOffline.bind(this));
    window.removeEventListener('online', this.setOnline.bind(this));
  }

  private setOffline() {
    this.setOnlineStatus({ status: false });
    this.colour = 'error';
  }

  private setOnline() {
    this.setOnlineStatus({ status: true });
    this.colour = 'success';
  }

  private logoutAndRedirect() {
    this.logout().then(() => this.$router.push({ name: 'login' }));
  }
}
