/* eslint-disable no-shadow */
import { API } from '@/API';
import { getDatabase } from '@/rxdb/Database.service';

/**
 * Shared globals are used to identify form inputs as specific known values
 * that we need to track such as 'Report Date', 'LFO fuel ROB' etc
 * This store will handle loading them and storing them
 */
const state = {
  consumptionConfigs: [],
  loading: false,
};

export const getters = {};

export const mutations = {
  setConsumptionConfigs(state, { consumptionConfigs }) {
    state.consumptionConfigs = Object.freeze(consumptionConfigs);
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    state.consumptionConfigs = [];
    state.loading = false;
  },
};

export const actions = {
  async init({ dispatch }) {
    // Load up the form schemas from RxDB and listen for changes
    const db = await getDatabase();
    db.consumptionconfig.$.subscribe(() => {
      dispatch('load');
    });

    await dispatch('load');
  },
  async load({ commit }) {
    const db = await getDatabase();
    const consumptionConfigs = await db.consumptionconfig
      .find()
      .exec();

    commit('setConsumptionConfigs', {
      consumptionConfigs: Object.freeze(consumptionConfigs.map((config) => config.toJSON())),
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
