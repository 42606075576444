




























































import Vue, { PropOptions } from 'vue';
import SDialog from '@stratumfive/ui-baseplate/src/components/SDialog/SDialog.vue';
import SButton from '@stratumfive/ui-baseplate/src/components/SButton/SButton.vue';
import { FormSubmission } from '@stratumfive/snapshot-forms/src/forms/types/FormDefinition';
import { Submission } from '@/rxdb/modules/submissions.module';
import {
  SubmissionType,
  VALID_REPORTS,
} from '@/workflow/submissionType';
import { Form } from '@/shared/types';
import isSubmissionWorkflowValid from '../../workflow/currentSubmissionValidation';

export default Vue.extend({
  name: 'WorkflowErrors',

  components: {
    SDialog,
    SButton,
  },

  props: {
    currentSubmission: {
      type: Object,
      required: false,
    } as PropOptions<FormSubmission>,
  },

  data(): any {
    return {
      isDialogDismissed: false,
      isSubmissionValid: true,
      previousSubmissionType: '',
    };
  },

  computed: {
    isDialogActive(): boolean {
      return !this.isDialogDismissed && !this.isSubmissionValid && this.currentSubmission.reportDate !== undefined;
    },
    forms(): Form[] {
      return this.$store.state.forms.latestForms;
    },
    submissions(): Submission[] {
      return this.$store.state.submissions.latestSubmissions;
    },
    validReportTypes(): Array<SubmissionType> {
      return VALID_REPORTS[this.previousSubmissionType];
    },
  },

  watch: {
    async currentSubmission() {
      const {
        isValidSubmissionOrder,
        previousSubmissionType,
      } = await isSubmissionWorkflowValid(this.currentSubmission, this.submissions);

      this.isSubmissionValid = isValidSubmissionOrder;
      this.previousSubmissionType = previousSubmissionType;
    },
  },

  methods: {
    getReportTitleByType(type: SubmissionType): string {
      // If we have a position submission subtype it's already formatted correctly for visual output so just return it
      if (type === SubmissionType.PositionAtSea
      || type === SubmissionType.PositionDrifting
      || type === SubmissionType.PositionInPort) {
        return type;
      }

      const form = this.forms.find(({ type: formType }) => type === formType);

      if (!form) {
        return 'Unknown';
      }

      return form.title;
    },
  },
});
