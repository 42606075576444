/* eslint-disable no-shadow */
import localForage from 'localforage';
import dayjs from 'dayjs';
import { API } from '@/API';

const LOAD_INTERVAL = 3; // Once every 3 days

// Create the localForage store so we can save the options offline
const dataOptionsStore = localForage.createInstance({
  name: 'data-options-store',
});

const state = {
  options: [],
  loading: false,
  lastUpdated: null,
  interval: null,
};

export const getters = {};

export const mutations = {
  /**
   * Reset the store
   */
  reset(state) {
    state.options = [];
    state.loading = false;
    state.lastUpdated = null;
    state.interval = null;
    clearInterval(state.interval);
  },
  setOptions(state, { options }) {
    state.options = Object.freeze(options);
  },
  setLastUpdated(state, { lastUpdated }) {
    state.lastUpdated = lastUpdated;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  setInterval(state, { interval }) {
    state.interval = interval;
  },
};

export const actions = {
  async init({ commit, dispatch }) {
    // Load the values we need out of the localforage store and back into state
    const lastUpdated = await dataOptionsStore.getItem('lastUpdated');
    commit('setLastUpdated', { lastUpdated });

    const options = await dataOptionsStore.getItem('options');
    if (options) {
      commit('setOptions', { options });
    }

    // Periodically check for options
    dispatch('loadDataOptions');
    const interval = setInterval(() => {
      dispatch('loadDataOptions');
    }, 60 * 1000 * 10); // Check for new options every 10 mins if we need to
    commit('setInterval', { interval });
  },
  async loadDataOptions({ commit, dispatch, state }) {
    // Check to see if we need to load, if we don't return early
    if (
      state.options.length !== 0
      && state.lastUpdated !== null
      && dayjs(state.lastUpdated).diff(dayjs(), 'day') < LOAD_INTERVAL) {
      return;
    }

    commit('setLoading', { loading: true });
    const options = await API.get('/data-options').then(
      ({ data }) => data,
    );

    dispatch('setOptions', { options });
    await dispatch('setLastUpdated', { lastUpdated: dayjs().toISOString() });

    commit('setLoading', { loading: false });
  },
  async reset({ state, commit }) {
    clearInterval(state.interval);
    commit('setInterval', { interval: null });
    commit('reset');
    await dataOptionsStore.clear();
  },
  async setLastUpdated({ commit }, { lastUpdated }) {
    await dataOptionsStore.setItem('lastUpdated', lastUpdated);
    commit('setLastUpdated', { lastUpdated });
  },
  async setOptions({ commit }, { options }) {
    await dataOptionsStore.setItem('options', options);
    commit('setOptions', { options });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
