import localForage from 'localforage';
import dayjs from 'dayjs';
import { API } from '@/API';
import { Form } from '@/shared/types';
import Timeout = NodeJS.Timeout;

// Create the localForage store so we can save the forms offline
const formStore = localForage.createInstance({
  name: 'form-store',
});

/* eslint-disable no-shadow */
const state = {
  form: null,
  latestForms: [],
  lastUpdated: null,
  loading: false,
  loadingForm: false,
};

/**
 * Get the key used to store a form
 * in the form store
 * @param id The form id
 * @param version The form version
 */
function getFormKey(id: string, version: number) {
  return `${id}-${version}`;
}

export const mutations = {
  setForm(state, { form }) {
    state.form = form;
  },

  setLatestForms(state, { latestForms }) {
    state.latestForms = latestForms;
  },

  setLastUpdated(state, { lastUpdated }) {
    state.lastUpdated = lastUpdated;
  },

  setLoading(state, { loading }) {
    state.loading = loading;
  },

  setLoadingForm(state, { loading }) {
    state.loadingForm = loading;
  },
};

const LOAD_NEW_FORMS_INTERVAL_IN_MS: number = 10 * 60 * 1000; // 10 minutes
let loadNewFormsInterval: Timeout;

export const actions = {
  async init({ commit, dispatch }): Promise<void> {
    // Load the values we need out of the localforage store and back into state
    const lastUpdated: string = await formStore.getItem('lastUpdated');

    commit('setLastUpdated', { lastUpdated });
    dispatch('loadForms');

    // Periodically check for new forms
    loadNewFormsInterval = setInterval(() => {
      dispatch('loadForms');
    }, LOAD_NEW_FORMS_INTERVAL_IN_MS);
  },

  async loadForms({ state, dispatch, commit }): Promise<void> {
    commit('setLoading', { loading: true });

    try {
      const { data: newForms }: { data: Form[] } = await API.get(
        `/forms/recent${state.lastUpdated ? `?lastUpdated=${state.lastUpdated}` : ''}`,
      );

      // eslint-disable-next-line prefer-destructuring
      let lastUpdated: string = state.lastUpdated;

      newForms.forEach((form) => {
        formStore.setItem(getFormKey(form.id, form.version), form);

        if (!lastUpdated || dayjs(form.updated).isAfter(dayjs(lastUpdated))) {
          lastUpdated = form.updated;
        }
      });

      // Update the last updated time to be the most recent updated time of the new forms
      await dispatch('setLastUpdated', { lastUpdated });
      await dispatch('setLatestForms');
    } finally {
      commit('setLoading', { loading: false });
    }
  },

  async reset(): Promise<void> {
    // Prevent memory leaks
    clearInterval(loadNewFormsInterval);
    await formStore.clear();
  },

  async setLastUpdated({ commit }, { lastUpdated }: { lastUpdated: string }): Promise<void> {
    await formStore.setItem('lastUpdated', lastUpdated);
    commit('setLastUpdated', { lastUpdated });
  },

  async setLatestForms({ commit }): Promise<void> {
    const latestForms: Form[] = await getLatestForms();
    commit('setLatestForms', { latestForms: Object.freeze(latestForms) });
  },

  async get({ commit }, { id, version }: { id: string, version: number}): Promise<Form> {
    commit('setForm', { form: null });
    commit('setLoadingForm', { loading: true });

    // First check to see if we have the form locally and use that
    const existingForm: Form = await formStore.getItem(getFormKey(id, version));

    if (existingForm) {
      commit('setForm', { form: existingForm });
      commit('setLoadingForm', { loading: false });

      return existingForm;
    }

    // If we don't have the form we should try and get it from the API, and store it locally
    try {
      const { data: form }: { data: Form } = await API.get(`/forms/${id}/${version}`);

      commit('setForm', { form });
      formStore.setItem(getFormKey(form.id, form.version), form);

      return form;
    } finally {
      commit('setLoadingForm', { loading: false });
    }
  },
};

/**
 * Load a form given its id and version
 */
export function loadForm(id: string, version: number): Promise<Form | null> {
  return formStore.getItem(getFormKey(id, version));
}

/**
 * Get the latest version of each type of form
 */
export async function getLatestForms(): Promise<Form[]> {
  const latestFormsObject = {};

  await formStore.iterate((form: Form, key) => {
    if (key === 'lastUpdated') {
      return;
    }

    if (!latestFormsObject[form.id] || latestFormsObject[form.id].version < form.version) {
      latestFormsObject[form.id] = form;
    }
  });

  // return latest forms
  return Object.keys(latestFormsObject)
    .map((id) => latestFormsObject[id]);
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
