
















































import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'SubmissionWarningDialog',

  props: {
    warnings: {
      type: Array,
      required: true,
    },
  },

  methods: {
    dismissDialog({ submit }) {
      if (submit) {
        this.$emit('confirm');
      } else {
        this.$emit('dismiss');
      }
    },
  },
});
