


































import Vue, { PropOptions } from 'vue';
import dayjs from 'dayjs';
import SDialog from '@stratumfive/ui-baseplate/src/components/SDialog/SDialog.vue';
import SAlert from '@stratumfive/ui-baseplate/src/components/SAlert/SAlert.vue';
import { Submission } from '@/rxdb/modules/submissions.module';
import { getDatabase } from '@/rxdb/Database.service';
import { RxDocument } from 'rxdb';
import { Subscription } from 'rxjs';

export default Vue.extend({
  name: 'NewVersionAvailable',

  components: {
    SDialog,
    SAlert,
  },

  props: {
    submission: {
      required: true,
      type: Object,
    } as PropOptions<Submission>,
  },

  data(): any {
    return {
      newVersionAvailable: false,
      newVersionTimestamp: '',
      dismissed: false,
      rxSubscription: null,
    };
  },

  computed: {
    isEditing(): boolean {
      return this.$route.name === 'edit-submission';
    },

    isOpen(): boolean {
      return this.newVersionAvailable && !this.dismissed;
    },
  },

  watch: {
    submission: {
      immediate: true,
      async handler() {
        // Cancel old subscription if there is one
        if (this.rxSubscription) {
          const subscription: Subscription = this.rxSubscription;
          subscription.unsubscribe();
        }

        // Find the rxdb submission so we can subscribe to changes
        const db = await getDatabase();
        const rxSubmission: RxDocument = await db.submissions.findOne().where('id').eq(this.submission.id).exec();

        this.rxSubscription = rxSubmission.$.subscribe((submissionFromRxDb: Submission) => {
          if (
            submissionFromRxDb.id === this.submission.id
            && dayjs(submissionFromRxDb.updated).isAfter(dayjs(this.submission.updated))) {
            this.newVersionTimestamp = `${dayjs(submissionFromRxDb.updated).format('YYYY-MM-DD hh:mm')}Z`;
            this.newVersionAvailable = true;
            this.dismissed = false;
          }
        });
      },
    },
  },

  methods: {
    loadNewVersion() {
      this.$emit('load');
      this.dismissed = true;
      this.newVersionTimestamp = '';
      this.newVersionAvailable = false;
    },
  },
});
