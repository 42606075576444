/* eslint-disable no-shadow */
import dayjs from 'dayjs';
import { getDatabase } from '@/rxdb/Database.service';

const state = {
  page: 0,
  limit: 10,
  submissions: [], // The current page of submissions
  total: 0,
  submission: null, // The current submission
  latestSubmissions: [],
  previousSubmission: null,
};

export const getters = {
  previousSubmissionDate({ previousSubmission }) {
    return previousSubmission ? previousSubmission.reportDate : '';
  },
};

export const mutations = {
  setPage(state, { page }) {
    state.page = page;
  },

  setLimit(state, { limit }) {
    state.limit = limit;
  },

  setTotal(state, { total }) {
    state.total = total;
  },

  setSubmissions(state, { submissions }) {
    state.submissions = submissions;
  },

  setLatestSubmissions(state, { latestSubmissions }) {
    state.latestSubmissions = latestSubmissions;
  },

  setPreviousSubmission(state, previousSubmission) {
    state.previousSubmission = previousSubmission;
  },

  setSubmission(state, { submission }) {
    state.submission = submission;
  },

  reset(state) {
    state.page = 0;
    state.limit = 10;
    state.submissions = [];
    state.total = 0;
    state.submission = null;
  },
};

export const actions = {
  async init({ dispatch }) {
    const db = await getDatabase();
    db.submissions.$.subscribe(() => {
      dispatch('loadPage');
      dispatch('loadLatest');
      dispatch('updateTotal');
    });

    await dispatch('loadLatest');
    await dispatch('updateTotal');
  },

  async loadPage({ state, commit, rootState }, options) {
    if (options?.page || options?.page === 0) {
      commit('setPage', { page: options.page });
    }

    if (options?.limit) {
      commit('setLimit', { limit: options.limit });
    }

    const db = await getDatabase();

    /*  This returns a list of submissions based on updated_at field.
     *  We need to use updated_at instead of reportDate,
     *  otherwise it will not detect the new versions of the submissions
     *  in the list (if someone edits it somewhere else).
     *  That's because we need to pull new submissions and new edits to old submission.
     *  Whenever a submission is edited, its updated_at timestamp is increased which will
     *  cause it to be returned the next time RxDB polls for updates.
     *  When it receives a new version of a submission, RxDB handles merging it with the existing
     *  local copy already stored in its database.
     */
    const submissions = await db.submissions
      .find({
        selector: {},
        sort: [{ reportDate: 'desc' }],
      })
      .limit(state.limit)
      .skip(state.page * state.limit)
      .exec();

    const startOfSyncDate = dayjs().subtract(rootState.ui.syncDuration, 'day');

    // Show only submissions after specific date based on reportDate not the updated date
    commit('setSubmissions', {
      submissions: Object.freeze(filterSubmissionsAfterReportDate({ submissions, startDate: startOfSyncDate })),
    });
  },

  async updateTotal({ commit, rootState }) {
    const db = await getDatabase();
    const submissions = await db.submissions
      .find()
      .exec();

    const startOfSyncDate = dayjs().subtract(rootState.ui.syncDuration, 'day');

    commit('setTotal', {
      total: filterSubmissionsAfterReportDate({ submissions, startDate: startOfSyncDate }).length,
    });
  },

  async fetchPreviousSubmission({ commit }, reportDate) {
    const db = await getDatabase();
    const [previousSubmission] = await db.submissions
      .find({
        selector: {
          $or: [
            { type: 'stoppage-resume' },
            { type: 'departure' },
            { 'content.82b18b3c-f638-48b6-b3c6-79597f711316': 'At Sea' },
          ],
        },
        sort: [{ reportDate: 'desc' }],
      })
      .where('reportDate')
      .lt(reportDate)
      .limit(1)
      .exec();
    commit('setPreviousSubmission', previousSubmission);
    return previousSubmission;
  },

  reset({ commit }) {
    commit('reset');
  },

  async load({ dispatch, commit }, { id }) {
    commit('setSubmission', { submission: null });
    const db = await getDatabase();
    const submission = await db.submissions
      .findOne()
      .where('id')
      .eq(id)
      .exec();

    const parsedSubmission = submission.toJSON();

    commit('setSubmission', { submission: parsedSubmission });
    dispatch('fetchPreviousSubmission', parsedSubmission.reportDate);
  },

  async save({ dispatch }, { submission }) {
    const db = await getDatabase();

    // If this isn't a draft we need to create submission globals
    // We need to do this first so that the submission globals exist before
    // the submission is synchronised with the backend
    if (submission.status === 'complete') {
      await dispatch('submissionGlobals/save', { submissionGlobals: submission.globalValues }, { root: true });
    }

    const submissionPayload = {
      id: submission.id,
      created: submission.created || new Date().toISOString(),
      reportDate: submission.reportDate,
      vesselId: submission.vessel.id,
      title: submission.title,
      status: submission.status,
      content: { ...submission.content },
      formId: submission.form.id,
      formVersion: submission.form.version,
      type: submission.type,
    };

    const newSubmission = await db.submissions.upsert(submissionPayload);

    return newSubmission;
  },

  async loadLatest({ commit }) {
    const db = await getDatabase();
    const submissions = await db.submissions
      .find({
        selector: {},
        sort: [{ reportDate: 'desc' }],
      })
      .limit(30)
      .exec();

    commit('setLatestSubmissions', { latestSubmissions: Object.freeze(submissions.map((s) => s.toJSON())) });
  },

  async remove(context, { submission }) {
    const db = await getDatabase();
    const rxdbSub = await db.submissions.findOne().where('id').eq(submission.id).exec();
    await rxdbSub.remove();
  },
};

function filterSubmissionsAfterReportDate({ submissions, startDate }) {
  return submissions
    .map((submission) => submission.toJSON())
    .filter((submission) => dayjs(submission.reportDate).isAfter(startDate));
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
