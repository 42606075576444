



























































import Vue, { PropOptions } from 'vue';
import { event } from 'vue-analytics';
import SnapshotForm from '@stratumfive/snapshot-forms/src/forms/components/SnapshotForm.vue';
import WorkflowErrors from '@/components/shared/WorkflowErrors.vue';
import SubmissionWarningsDialog from '@/components/submission/SubmissionWarningsDialog.vue';
import { DELAY, LONG_DELAY } from '@/constants/ui';
import { Form } from '../../shared/types';
import { Submission } from '../../rxdb/modules/submissions.module';
import { SubmissionGlobal } from '../../rxdb/modules/submission-globals.module';
import { SharedGlobal } from '../../rxdb/modules/shared-globals.module';
import { ConsumptionConfig } from '../../rxdb/modules/consumption-config.module';
import NewVersionAvailable from './NewVersionAvailable.vue';

export default Vue.extend({
  components: {
    SnapshotForm,
    NewVersionAvailable,
    SubmissionWarningsDialog,
    WorkflowErrors,
  },

  props: {
    form: {
      required: true,
      type: Object,
    } as PropOptions<Form>,

    globals: {
      required: true,
      type: Array,
    } as PropOptions<SubmissionGlobal[]>,

    sharedGlobals: {
      required: true,
      type: Array,
    } as PropOptions<SharedGlobal[]>,

    consumptionConfig: {
      required: true,
      type: Array,
    } as PropOptions<ConsumptionConfig[]>,

    vesselGlobals: {
      required: true,
      type: Array,
    } as PropOptions<any[]>,

    dataOptions: {
      required: true,
      type: Array,
    } as PropOptions<any[]>,

    submissionId: {
      required: true,
      type: String,
    },
  },

  data(): any {
    return {
      loading: false,
      success: false,
      error: false,
      saving: false,
      currentSubmission: null,
      formOutput: null,
      warnings: [],
      redirectionTimeout: null,
    };
  },

  computed: {
    submission(): Submission {
      return this.$store.state.submissions.submission || { version: 0 };
    },

    initialReportDate(): string {
      return this.submission.reportDate;
    },

    previousSubmissionDate(): string {
      return this.$store.getters['submissions/previousSubmissionDate'];
    },

    filteredGlobals(): SubmissionGlobal[] {
      // Filter out any globals that are for the current submission
      return this.globals.filter(global => global.submissionId !== this.submission?.id);
    },

    longDelay(): number {
      return LONG_DELAY;
    },
  },

  created() {
    this.load();
  },

  beforeDestroy() {
    clearTimeout(this.redirectionTimeout);
    this.redirectionTimeout = null;
  },

  methods: {
    submitForm() {
      this.save(this.formOutput);
    },

    async save(output) {
      this.saving = true;
      this.success = false;
      this.error = false;

      // Clear warnings before save (after confirmation button is clicked)
      // This causes the warnings dialog to close and allow other elements
      // to emit events on click
      this.warnings = [];

      try {
        this.$store.dispatch('submissions/save', { submission: { ...output, created: this.submission?.created } });
        this.success = true;

        event('submission', 'save', output.status);

        this.redirectionTimeout = setTimeout(() => {
          this.saving = false;

          const query = output.status === 'draft' ? { draftCreated: output.id } : { submissionCreated: output.id };
          this.$router.push({
            name: 'home',
            query,
          });
        }, DELAY);
      } catch (e) {
        console.error(e);
        this.error = true;
        this.saving = false;
      }
    },

    async load() {
      this.loading = true;
      await this.$store.dispatch('submissions/load', { id: this.submissionId });
      this.loading = false;
    },

    loadNewVersion() {
      // For some reason simple calling this.load() causes
      // the save draft and submit buttons in the forms module
      // to become unresponsive and they stop firing any events
      // meaning the form can't be submitted.
      // Until we can figure this out simply reload the whole page
      window.location.reload();
    },

    showWarningsDialog({ warnings, output }) {
      this.formOutput = output;
      this.warnings = warnings;
    },

    async handleNewReportDate(reportDate) {
      const response = await this.$store.dispatch('submissions/fetchPreviousSubmission', reportDate);

      if (response.reportDate === this.initialReportDate) {
        // This is a case where the date was edited forwards in time but not past the next report in line.
        // Only in such a case must we run fetchPreviousSubmission again with this initialDate,
        // to access one more report backwards; beyond it's own "prior-self"
        await this.$store.dispatch('submissions/fetchPreviousSubmission', this.initialReportDate);
      }
    },
  },
});
