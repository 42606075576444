export enum SubmissionType {
  Arrival = 'arrival',
  Departure = 'departure',
  Position = 'position-combined',
  PositionAtSea = 'At Sea',
  PositionDrifting = 'Drifting',
  PositionInPort = 'In Port',
  Stoppage = 'stoppage',
  StoppageResume = 'stoppage-resume',
}

type SubmissionValidationMatrix = Record<SubmissionType, Array<SubmissionType>>

const IN_PORT_SUBMISSIONS = [
  SubmissionType.Departure,
  SubmissionType.PositionInPort,
];
/* Making way is defined by whether or not a ship has any speed through water.
 * For our purposes this means the vessel has been actively moving toward
 * its destination immediately prior to this submission.
 */
const MAKING_WAY_SUBMISSIONS = [
  SubmissionType.Arrival,
  SubmissionType.PositionAtSea,
  SubmissionType.Stoppage,
];
const STOPPED_SUBMISSIONS = [
  SubmissionType.PositionDrifting,
  SubmissionType.StoppageResume,
];

/*
 * Key: Previous submission
 * Value: Current submission
 * e.g. If the report that came before me is Arrival, I must be one of IN_PORT_SUBMISSIONS
 */
export const VALID_REPORTS: SubmissionValidationMatrix = {
  [SubmissionType.Arrival]: IN_PORT_SUBMISSIONS,
  [SubmissionType.Departure]: MAKING_WAY_SUBMISSIONS,
  [SubmissionType.Position]: [], // Should always refer to one of the 3 subtypes below
  [SubmissionType.PositionAtSea]: MAKING_WAY_SUBMISSIONS,
  [SubmissionType.PositionDrifting]: STOPPED_SUBMISSIONS,
  [SubmissionType.PositionInPort]: IN_PORT_SUBMISSIONS,
  [SubmissionType.Stoppage]: STOPPED_SUBMISSIONS,
  [SubmissionType.StoppageResume]: MAKING_WAY_SUBMISSIONS,
};

/*
 * Returns true if the given submission type is a timeline submission,
 * rather than a simple information submission
 */
export const canValidateSubmissionType = (
  type: string,
): boolean => Object.values(SubmissionType).includes(type as SubmissionType);

const isValidSubmissionOrder = (
  currentSubmissionType: string,
  previousSubmissionType: string,
): boolean => VALID_REPORTS[previousSubmissionType].includes(currentSubmissionType);

export default isValidSubmissionOrder;
