import Vue from 'vue';
import Vuex, { Dispatch } from 'vuex';
import { init, removeDatabase } from '@/rxdb/Database.service';

// Store modules
import ui from './modules/ui';
import user from './modules/user';
import submissions from './modules/submissions';
import forms from './modules/forms';
import formSchemas from './modules/form-schemas';
import submissionGlobals from './modules/submission-globals';
import submissionVersions from './modules/submission-versions';
import vesselGlobals from './modules/vessel-globals';
import sharedGlobals from './modules/shared-globals';
import consumptionConfigs from './modules/consumption-config';
import dataOptions from './modules/data-options';
import systemCompatibility from './modules/system-compatibility';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    ui,
    user,
    submissions,
    forms,
    formSchemas,
    submissionGlobals,
    vesselGlobals,
    submissionVersions,
    sharedGlobals,
    consumptionConfigs,
    dataOptions,
    systemCompatibility,
  },
  actions: {
    async startUp({ dispatch, rootState }: { dispatch: Dispatch, rootState: any }) {
      if (rootState.user.token) {
        // Setup rxdb
        await init(rootState.user.token, rootState.ui.syncDuration);
        // Initialise store modules
        await Promise.all([
          await dispatch('submissions/init'),
          await dispatch('forms/init'),
          await dispatch('formSchemas/init'),
          await dispatch('vesselGlobals/init', { vesselId: rootState.user.vessel.id }),
          await dispatch('sharedGlobals/init'),
          await dispatch('consumptionConfigs/init'),
          await dispatch('dataOptions/init'),
        ]);
      }
    },
    async shutDown({ dispatch }) {
      await Promise.all([
        await removeDatabase(),
        await dispatch('submissions/reset'),
        await dispatch('formSchemas/reset'),
        await dispatch('submissionGlobals/reset'),
        await dispatch('forms/reset'),
        await dispatch('submissionVersions/reset'),
        await dispatch('vesselGlobals/reset'),
        await dispatch('sharedGlobals/reset'),
        await dispatch('consumptionConfigs/reset'),
        await dispatch('dataOptions/reset'),
      ]);
    },
  },
});
