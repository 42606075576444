/* eslint-disable no-shadow */
export const mutations = {
  setSystemCheckOutput(state, { systemCheckOutput }) {
    state.systemCheckOutput = [];
    state.systemCheckOutput.push(...systemCheckOutput);
  },
  setDataReady(state, payload) {
    state.dataReady = payload;
  },
  setVersion(state, payload) {
    state.version = payload;
  },
  setLastCheckTime(state, { outputTime }) {
    state.lastCheckTime = outputTime;
  },
};

export const getters = {
  getDataReady(state) {
    return state.dataReady;
  },
  getSystemOutputAsString(state) {
    let output = '';

    if (state.lastCheckTime) {
      output += `System check performed on ${state.lastCheckTime}.\n`;
    }

    if (state.version) {
      output += `Snapshot version: ${state.version}\n\n`;
    }

    if (!state.systemCheckOutput.length) return output;

    state.systemCheckOutput.forEach((row) => {
      const rowCheckString = (row.result)
        ? 'Check was successful.' : 'Check was unsuccessful.';
      output += `${row.parameter}: ${rowCheckString}\n`;

      if (row.errorMessage) {
        output += `Error returned: ${row.errorMessage}\n`;
      }

      if (row.furtherInformation) {
        output += `Further information: ${row.furtherInformation}\n`;
      }

      output += '\n';
    });

    return output;
  },
};

export const state = {
  systemCheckOutput: [],
  dataReady: false,
  version: undefined,
  lastCheckTime: undefined,
};

export default {
  state,
  mutations,
  getters,
  namespaced: true,
};
