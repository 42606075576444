

























































import Vue, { PropOptions } from 'vue';
import { event } from 'vue-analytics';
import SnapshotForm from '@stratumfive/snapshot-forms/src/forms/components/SnapshotForm.vue';
import WorkflowErrors from '@/components/shared/WorkflowErrors.vue';
import { Submission } from '@/rxdb/modules/submissions.module';
import SubmissionWarningsDialog from '@/components/submission/SubmissionWarningsDialog.vue';
import { DELAY, LONG_DELAY } from '@/constants/ui';
import { Form } from '../../shared/types';
import { SubmissionGlobal } from '../../rxdb/modules/submission-globals.module';
import { SharedGlobal } from '../../rxdb/modules/shared-globals.module';
import { ConsumptionConfig } from '../../rxdb/modules/consumption-config.module';

export default Vue.extend({
  name: 'ReportContent',

  components: {
    SnapshotForm,
    SubmissionWarningsDialog,
    WorkflowErrors,
  },

  props: {
    form: {
      required: true,
      type: Object,
    } as PropOptions<Form>,

    globals: {
      required: true,
      type: Array,
    } as PropOptions<SubmissionGlobal[]>,

    sharedGlobals: {
      required: true,
      type: Array,
    } as PropOptions<SharedGlobal[]>,

    consumptionConfig: {
      required: true,
      type: Array,
    } as PropOptions<ConsumptionConfig[]>,

    vesselGlobals: {
      required: true,
      type: Array,
    } as PropOptions<any[]>,

    dataOptions: {
      required: true,
      type: Array,
    } as PropOptions<any[]>,

    previousSubmissions: {
      required: false,
      type: Array,
      default: () => [],
    } as PropOptions<Submission[]>,

    allowDuplicateTimestamps: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },

  data(): any {
    return {
      success: false,
      error: false,
      currentSubmission: null,
      formOutput: null,
      warnings: [],
      redirectionTimeout: null,
    };
  },

  computed: {
    longDelay(): number {
      return LONG_DELAY;
    },

    previousSubmissionDate(): string {
      return this.$store.getters['submissions/previousSubmissionDate'];
    },
  },

  beforeDestroy() {
    clearTimeout(this.redirectionTimeout);
    this.redirectionTimeout = null;
  },

  methods: {
    submitForm() {
      this.save(this.formOutput);
    },

    async save(output) {
      this.success = false;
      this.error = false;

      try {
        await this.$store.dispatch('submissions/save', { submission: output });
        this.success = true;

        event('submission', 'save', output.status);

        this.redirectionTimeout = setTimeout(() => {
          const query = output.status === 'draft' ? { draftCreated: output.id } : { submissionCreated: output.id };
          this.$router.push({
            name: 'home',
            query,
          });
        }, DELAY);
      } catch (e) {
        console.error(e);
        this.error = true;
      }
    },

    showWarningsDialog({ warnings, output }) {
      this.formOutput = output;
      this.warnings = warnings;
    },

    async handleNewReportDate(reportDate) {
      await this.$store.dispatch('submissions/fetchPreviousSubmission', reportDate);
    },
  },
});
