import { RxDatabase, RxJsonSchema, RxCollection } from 'rxdb';
import dayjs from 'dayjs';
import { RxModule } from './RxModule.interface';

export type FormSchema = {
  id: string;
  version: number;
  created: string;
  updated: string;
  title: string;
  description?: string;
  color: string;
  type?: string;
  config?: any;
  published: boolean;
}

const schemaV1: RxJsonSchema<FormSchema> = {
  title: 'Form Schema',
  version: 0,
  description: 'Describes a Snapshot Form Schema',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
      default: '',
    },
    version: {
      type: 'number',
    },
    created: {
      type: 'string',
      default: '',
    },
    updated: {
      type: 'string',
      default: '',
    },
    title: {
      type: 'string',
    },
    description: {
      type: 'string',
      default: '',
    },
    color: {
      type: 'string',
    },
    type: {
      type: ['string', 'null'],
      default: 'default',
    },
    config: {
      type: ['object', 'null'],
      default: {},
    },
    published: {
      type: 'boolean',
    },
  },
  required: ['id', 'created', 'version', 'type'],
  indexes: ['created', 'version'],
};

let collection: RxCollection<FormSchema>;

const pullQueryBuilder = (doc) => {
  if (!doc) {
    // the first pull does not have a start-document
    doc = {
      id: '',
      updated: dayjs().subtract(2, 'year').toISOString(),
    };
  }
  const query = `{
          formSchemasForRxDB(
              lastId: "${doc.id}",
              minUpdated: "${doc.updated}"
              limit: 20,
          ) {
            id
            version
            created
            updated
            deleted
            published
            title
            description
            color
            type
            config
          }
      }`;

  return {
    query,
    variables: {},
  };
};

const formSchemasModule: RxModule = {
  async init(db: RxDatabase) {
    collection = await db.collection({
      name: 'formschemas', // For some reason it doesn't like it being camel case formSchemas
      schema: schemaV1,
    });

    return collection;
  },
  initReplication(authToken: string, baseUrl: string) {
    const replicationState = collection.syncGraphQL({
      url: `${baseUrl}/graphql`, // url to the GraphQL endpoint
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      pull: {
        queryBuilder: pullQueryBuilder, // the queryBuilder from above
      },
      deletedFlag: 'deleted', // the flag which indicates if a pulled document is deleted
      live: true,
      liveInterval: 1000 * 60,
    });

    replicationState.error$.subscribe((error) => {
      console.error('RxDB: something went wrong getting form schemas');
      console.error(error);
    });

    return replicationState;
  },
};

export default formSchemasModule;
