


























import Vue, { PropOptions } from 'vue';
import SnapshotForm from '@stratumfive/snapshot-forms/src/forms/components/SnapshotForm.vue';
import { ConsumptionConfig } from '@/rxdb/modules/consumption-config.module';
import { SharedGlobal } from '@/rxdb/modules/shared-globals.module';
import NewVersionAvailable from './NewVersionAvailable.vue';
import { Form } from '../../shared/types';
import { Submission } from '../../rxdb/modules/submissions.module';

export default Vue.extend({
  components: {
    SnapshotForm,
    NewVersionAvailable,
  },

  props: {
    form: {
      required: true,
      type: Object,
    } as PropOptions<Form>,

    globals: {
      required: true,
      type: Array,
    } as PropOptions<any[]>,

    submissionId: {
      required: true,
      type: String,
    },

    sharedGlobals: {
      required: true,
      type: Array,
    } as PropOptions<SharedGlobal[]>,

    consumptionConfig: {
      required: true,
      type: Array,
    } as PropOptions<ConsumptionConfig[]>,

    vesselGlobals: {
      required: true,
      type: Array,
    } as PropOptions<any[]>,

    dataOptions: {
      required: true,
      type: Array,
    } as PropOptions<any[]>,
  },

  data(): any {
    return {
      loading: false,
    };
  },

  computed: {
    submission(): Submission {
      return this.$store.state.submissions.submission;
    },

    previousSubmissionDate(): string {
      return this.$store.getters['submissions/previousSubmissionDate'];
    },
  },

  created() {
    this.load();
  },

  methods: {
    edit() {
      this.$router.push({
        name: 'edit-submission',
        params: {
          submissionId: this.submissionId,
        },
      });
    },

    async load() {
      this.loading = true;
      await this.$store.dispatch('submissions/load', { id: this.submissionId });
      this.loading = false;
    },
  },
});
