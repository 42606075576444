import { getDatabase } from '@/rxdb/Database.service';

/* eslint-disable no-shadow */
const state = {
  submissionGlobals: [],
  loading: false,
};

export const mutations = {
  setLoading(state, { loading }) {
    state.loading = loading;
  },

  setSubmissionGlobals(state, { submissionGlobals }) {
    state.submissionGlobals = submissionGlobals;
  },

  reset(state) {
    state.submissionGlobals = [];
  },
};

export const actions = {
  async load({ state, dispatch, commit }) {
    commit('setLoading', { loading: true });
    const db = await getDatabase();

    const submissionGlobals = await db.submissionglobals
      .find({
        selector: {},
        sort: [{ reportDate: 'desc' }],
      })
      .exec();

    commit('setSubmissionGlobals', { submissionGlobals: Object.freeze(submissionGlobals.map((g) => g.toJSON())) });
    commit('setLoading', { loading: false });
  },

  reset({ commit }) {
    commit('reset');
  },

  async save({ dispatch }, { submissionGlobals }) {
    const db = await getDatabase();

    const inserts = submissionGlobals.map((global) => db.submissionglobals.upsert({
      id: global.id,
      reportDate: global.reportDate,
      globalId: global.globalId,
      fieldId: global.fieldId,
      value: `${global.value}`,
      submissionId: global.submissionId,
    }));

    await Promise.all(inserts);
    await dispatch('load');
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
