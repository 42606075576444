import { RxDatabase, RxJsonSchema, RxCollection } from 'rxdb';
import dayjs from 'dayjs';
import { RxModule } from './RxModule.interface';

export type SharedGlobal = {
  id: string;
  date: string;
  updated: string;
  position: number;
  name: string;
  label: string;
}

const schemaV1: RxJsonSchema<SharedGlobal> = {
  title: 'Shared Global',
  version: 0,
  description: 'Describes a known metric name in Snapshot shared between forms',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
      default: '',
    },
    date: {
      type: 'string',
      default: '',
    },
    updated: {
      type: 'string',
      default: '',
    },
    position: {
      type: 'number',
    },
    name: {
      type: 'string',
    },
    label: {
      type: 'string',
      default: '',
    },
  },
  required: ['id', 'name', 'label'],
  indexes: ['name', 'label'],
};

let collection: RxCollection<SharedGlobal>;

const pullQueryBuilder = (doc) => {
  if (!doc) {
    // the first pull does not have a start-document
    doc = {
      position: 0,
      updated: dayjs().subtract(2, 'year').toISOString(),
    };
  }

  const query = `{
          sharedGlobalsForRxDB(
              lastPosition: "${doc.position}",
              minUpdated: "${doc.updated}"
              limit: 200,
          ) {
            id
            date
            updated
            position
            name
            label
          }
      }`;

  return {
    query,
    variables: {},
  };
};

const sharedGlobalsModule: RxModule = {
  async init(db: RxDatabase) {
    collection = await db.collection({
      name: 'sharedglobals', // For some reason it doesn't like it being camel case formSchemas
      schema: schemaV1,
    });

    return collection;
  },
  initReplication(authToken: string, baseUrl: string) {
    const replicationState = collection.syncGraphQL({
      url: `${baseUrl}/graphql`, // url to the GraphQL endpoint
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      pull: {
        queryBuilder: pullQueryBuilder, // the queryBuilder from above
      },
      deletedFlag: 'deleted', // the flag which indicates if a pulled document is deleted
      live: true,
      liveInterval: 1000 * 60 * 60, // 1 hour
    });

    replicationState.error$.subscribe((error) => {
      console.error('RxDB: something went wrong getting shared globals');
      console.error(error);
    });

    return replicationState;
  },
};

export default sharedGlobalsModule;
