import { RxDatabase, RxJsonSchema, RxCollection } from 'rxdb';
import dayjs from 'dayjs';
import { RxModule } from './RxModule.interface';

export type ConsumptionConfig = {
  id: string;
  updated: string;
  position: number;
  fuel: string;
  speed: number;
  ballastConsumption: number;
  ladenConsumption: number;
}

const schemaV1: RxJsonSchema<ConsumptionConfig> = {
  title: 'Consumption Configuration',
  version: 0,
  description: 'Describes the amount of fuel a vessel should burn at different speeds and conditions',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
      default: '',
    },
    updated: {
      type: 'string',
      default: '',
    },
    position: {
      type: 'number',
    },
    fuel: {
      type: 'string',
    },
    speed: {
      type: 'number',
    },
    ballastConsumption: {
      type: 'number',
    },
    ladenConsumption: {
      type: 'number',
    },
  },
  required: ['id', 'fuel', 'speed'],
  indexes: ['fuel', 'speed'],
};

let collection: RxCollection<ConsumptionConfig>;

const pullQueryBuilder = (syncDuration: number) => (doc) => {
  if (!doc) {
    // the first pull does not have a start-document
    doc = {
      position: 0,
      updated: dayjs().subtract(syncDuration, 'day').toISOString(),
    };
  }

  const query = `{
          vesselConsumptionConfigForRxDB(
              lastPosition: "${doc.position}",
              minUpdated: "${doc.updated}"
              limit: 200,
          ) {
            id
            updated
            position
            fuel
            speed
            ballastConsumption
            ladenConsumption
          }
      }`;

  return {
    query,
    variables: {},
  };
};

const consumptionConfigModule: RxModule = {
  async init(db: RxDatabase) {
    collection = await db.collection({
      name: 'consumptionconfig',
      schema: schemaV1,
    });

    return collection;
  },
  initReplication(authToken: string, baseUrl: string, syncDuration: number) {
    const replicationState = collection.syncGraphQL({
      url: `${baseUrl}/graphql`, // url to the GraphQL endpoint
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      pull: {
        queryBuilder: pullQueryBuilder(730), // the queryBuilder from above
        modifier: (doc) => ({
          ...doc,
          id: `${doc.id}`,
        }),
      },
      deletedFlag: 'deleted', // the flag which indicates if a pulled document is deleted
      live: true,
      liveInterval: 1000 * 60 * 60 * 24, // 1 day
    });

    replicationState.error$.subscribe((error) => {
      console.error('RxDB: something went wrong getting consumption config');
      console.error(error);
    });

    return replicationState;
  },
};

export default consumptionConfigModule;
