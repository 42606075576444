import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueSentry } from '@sentry/integrations';
import VueMeta from 'vue-meta';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import '@stratumfive/ui-baseplate/dist/stratum-vue.css'; // Baseplate component CSS
import '@stratumfive/ui-baseplate/src/sass/main.scss'; // Baseplate Helper classes and Toolkit

import SCard from '@stratumfive/ui-baseplate/src/components/SCard/SCard.vue';
import SButton from '@stratumfive/ui-baseplate/src/components/SButton/SButton.vue';
import STable from '@stratumfive/ui-baseplate/src/components/STable/STable.vue';
import SDialog from '@stratumfive/ui-baseplate/src/components/SDialog/SDialog.vue';
import SAlert from '@stratumfive/ui-baseplate/src/components/SAlert/SAlert.vue';
import STooltip from '@stratumfive/ui-baseplate/src/components/STooltip/STooltip.vue';
import SActionDialog from '@stratumfive/ui-baseplate/src/components/SActionDialog/SActionDialog.vue';

import './registerServiceWorker';
import VueAnalytics, { set } from 'vue-analytics';
import vuetify from './plugins/vuetify';

import App from './App.vue';
import router from './router';
import store from './store';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

dayjs.extend(utc);
dayjs.extend(timezone);

Vue.use(VueMeta);
Vue.component('SCard', SCard);
Vue.component('SButton', SButton);
Vue.component('STable', STable);
Vue.component('SDialog', SDialog);
Vue.component('SAlert', SAlert);
Vue.component('STooltip', STooltip);
Vue.component('STooltip', STooltip);
Vue.component('SActionDialog', SActionDialog);

if (process.env.NODE_ENV !== 'development' && !process.env.VUE_APP_E2E) {
  // Initialise sentry for error tracking
  Sentry.init({
    dsn: 'https://816f7b2fa63e4e43aabb35a87cf70e39@sentry.io/1371891',
    integrations: [new VueSentry({ Vue })],
    release: process.env.VUE_APP_RELEASE || 'dev',
  });
}

// Ask for storage persistance
if (navigator && navigator.storage) {
  navigator.storage.persist()
    .then((persisted) => {})
    .catch((e) => {
      console.error('Could not request persistance');
      console.error(e);
    });
}

// Initialise analytics
Vue.use(VueAnalytics, {
  id: 'UA-69119390-5',
  router,
  debug: {
    sendHitTask:
      process.env.NODE_ENV !== 'development' && !process.env.VUE_APP_E2E,
  },
});

const vuexJSON = localStorage.getItem('vuex');
if (vuexJSON) {
  const vuex = JSON.parse(vuexJSON);
  if (vuex && vuex.user && vuex.user.user && vuex.user.user.id) {
    const { user } = vuex.user;
    if (process.env.NODE_ENV !== 'development' && !process.env.VUE_APP_E2E) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user.id,
          username: user.username,
        });
      });
      set('userId', user.id);
    }
  }
}

Vue.config.productionTip = false;
Vue.config.performance = false;

Vue.filter('pluralize', (word, amount) => (amount > 1 ? `${word}s` : word));

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
