









































import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  Action, Mutation, Getter, State,
} from 'vuex-class';
import dayjs from 'dayjs';
import SApp from '@stratumfive/ui-baseplate/src/components/SApp/SApp.vue';

import { event } from 'vue-analytics';
import EventBus from '@/EventBus';
import UserOnlineStatus from '@/components/shared/UserOnlineStatus.vue';
import AuthorNameDialog from '@/components/shared/AuthorNameDialog.vue';
import { FormSchema } from './rxdb/modules/form-schemas.module';
import { Form } from './shared/types';
import { getReplicationInfo } from './rxdb/Database.service';

// This is an arbitrary comment by Dylan, being pushed to master
// to hopefully trigger a rebuild for the downgrade of snapshot-foms and basplate-ui to safer forms.

export default Vue.extend({
  name: 'App',
  components: {
    SApp,
    UserOnlineStatus,
    AuthorNameDialog,
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s - Stratum Snapshot',
  },
  data(): any {
    return {
      initialising: true,
    };
  },
  computed: {
    formSchemas(): FormSchema[] {
      return this.$store.state.formSchemas.formSchemas.filter((formSchema) => formSchema.published);
    },
    activeForms(): Form[] {
      const formSchemaIds = this.formSchemas.map((formSchema) => formSchema.id);
      return this.$store.state.forms.latestForms.filter((form) => formSchemaIds.includes(form.formSchemaId));
    },
    routes(): any {
      return [
        {
          type: 'link',
          link: { name: 'home' },
          icon: 'explore',
          title: 'Snapshot Overview',
          exact: true,
        },
        {
          type: 'group',
          heading: 'Reports',
          icon: 'assignment',
          routes: [
            {
              type: 'link',
              link: { name: 'reports' },
              icon: 'view_list',
              title: 'All Reports',
              exact: true,
            },
            ...this.activeForms.map((report) => ({
              type: 'link',
              title: report.title,
              icon: 'assignment',
              link: {
                name: 'report',
                params: {
                  id: `${report.id}`,
                  version: `${report.version}`,
                },
              },
            })),
          ],
        },
        {
          type: 'link',
          link: { name: 'guide' },
          icon: 'help',
          title: 'User Guide',
          exact: true,
        },
        {
          type: 'link',
          link: { name: 'settings' },
          icon: 'settings',
          title: 'Settings',
          exact: true,
        },
      ];
    },
    loading(): boolean {
      if (this.user) {
        return (this.initialising || this.progress !== 100);
      }

      return this.initialising;
    },
    user(): any {
      return this.$store.state.user.user?.username;
    },
    progress(): any {
      return getReplicationInfo().initialProgess;
    },
    updateAvailable(): boolean {
      return this.$store.state.ui.updateAvailable;
    },
  },
  async created() {
    await this.$store.dispatch('startUp');
    this.initialising = false;
  },
  methods: {
    installNewServiceWorker() {
      this.$store.dispatch('ui/installNewServiceWorker');
    },
  },
});
