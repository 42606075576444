





































import Vue from 'vue';
import SDialog from '@stratumfive/ui-baseplate/src/components/SDialog/SDialog.vue';
import SInputText from '@stratumfive/ui-baseplate/src/components/SInputText/SInputText.vue';

export default Vue.extend({
  name: 'AuthorNameDialog',
  components: {
    SInputText,
    SDialog,
  },
  data(): any {
    return {
      name: '',
      rules: {
        required: (value) => !!value || 'Required.',
      },
      valid: false,
    };
  },
  computed: {
    open(): boolean {
      return !!(this.$store.state.user.user?.id && !this.$store.state.user.authorName);
    },
  },
  methods: {
    setName() {
      if (this.$refs.form.value) {
        this.$store.commit('setAuthorName', { authorName: this.name });
      }
    },
  },
});
