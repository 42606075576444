import { getDatabase, getReplicationState } from '../../rxdb/Database.service';

/* eslint-disable no-shadow */
const state = {
  formSchemas: [],
  loading: false,
};

export const mutations = {
  setFormSchemas(state, { formSchemas }) {
    state.formSchemas = formSchemas;
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  reset(state) {
    state.formSchemas = [];
    state.loading = false;
  },
};

export const actions = {
  async init({ dispatch }) {
    // Load up the form schemas from RxDB and listen for changes
    const db = await getDatabase();
    db.formschemas.$.subscribe(() => {
      dispatch('loadFormSchemas');
    });

    await dispatch('loadFormSchemas');
  },
  async loadFormSchemas({ commit }) {
    const db = await getDatabase();
    const formSchemas = await db.formschemas
      .find()
      .exec();

    commit('setFormSchemas', { formSchemas: Object.freeze(formSchemas.map((f) => f.toJSON())) });
  },
  async syncFormSchemas({ commit }) {
    commit('setLoading', { loading: true });
    await getDatabase();
    const replicationState = getReplicationState('formschemas');
    await replicationState?.run();
    commit('setLoading', { loading: false });
  },
  reset({ commit }) {
    commit('reset');
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
