import axios from 'axios';
import moment from 'moment';
import store from './store';
import router from './router';
import {
  TOKEN_STORAGE_KEY,
} from './store/helpers/user-constants';

export const STATIC_API_URL = 'https://snapshot-api-static.stratumfive.com/next';

export const API = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
});

const authToken = localStorage.getItem(TOKEN_STORAGE_KEY);
if (authToken) {
  setHeader('Authorization', `Bearer ${authToken}`);
}

// If the hostname includes the word static we know that Snapshot is being loaded using
// the static IP version. Some vessels can only whitelist Snapshot by a static IP address.
// If the UI is loaded over static IP we also need to make sure it uses a backend endpoint
// With a static IP
const needsStaticIp = window.location.hostname.includes('snapshot-static.stratumfive.com');

// Check the primary endpoint url and the backup endpoint url. If the primary
// url doesn't work we try the secondary. This helps us to get around DNS caching
// issues
let baseUrl = !needsStaticIp ? process.env.VUE_APP_API_URL : STATIC_API_URL;

// eslint-disable-next-line no-async-promise-executor
const checkedUrls = new Promise(async (resolve) => {
  try {
    await axios.get(baseUrl as string);
    return resolve(true);
  } catch (e) {
    console.error('Primary API uncontactable');
  }

  try {
    await axios.get(process.env.VUE_APP_API_URL_BACKUP as string);
    baseUrl = process.env.VUE_APP_API_URL_BACKUP;
    return resolve(true);
  } catch (e) {
    console.error('Primary API uncontactable');
  }

  // If it gets to this point we are probably disconnected from the net so just resolve anyway
  return resolve(true);
});

API.interceptors.request.use(async (config) => {
  // Before sending any requests wait until we have decided what URL to use
  await checkedUrls;
  // eslint-disable-next-line no-param-reassign
  config.baseURL = baseUrl;

  // Add the version of the app the user is currently on
  // eslint-disable-next-line no-param-reassign
  config.headers['snapshot-ui-version'] = process.env.VUE_APP_HASH;

  return config;
});

API.interceptors.response.use(
  (res) => {
    // We no longer need to do this as the service worker should
    // auto update
    // TODO - remove this once we are sure it is working
    const latestUiVersion = res.headers['snapshot-ui-version'];
    const lastUpdated = moment(res.headers['snapshot-ui-updated']);

    // const localLastUpdated = moment(process.env.VUE_APP_BUILD_DATE);
    // const hash = process.env.VUE_APP_HASH
    //   ? process.env.VUE_APP_HASH.substring(0, 7)
    //   : '';

    // if (
    //   latestUiVersion
    //   && latestUiVersion !== hash
    //   && localLastUpdated.isBefore(lastUpdated)
    // ) {
    //   store.commit('ui/setUiLastUpdated', {
    //     lastUpdated: res.headers['snapshot-ui-updated'],
    //   });
    //   store.commit('ui/setUpdateAvailable', {
    //     updateAvailable: true,
    //   });
    // }

    return res;
  },
  (error) => {
    // If we get an authorized response ask the user to login
    if (
      error.response
      && error.response.data
      && error.response.data.statusCode === 401
    ) {
      store.commit('setUser', { user: null });
      store.commit('setToken', { token: '' });
      router.push({ name: 'login' });
    }
    return Promise.reject(error);
  },
);

export function setHeader(key, value) {
  API.defaults.headers.common[key] = value;
}

export function removeHeader(key) {
  delete API.defaults.headers.common[key];
}
