import { RxDatabase, RxJsonSchema, RxCollection } from 'rxdb';
import dayjs from 'dayjs';
import { RxModule } from './RxModule.interface';

export type SubmissionGlobal = {
  id: string;
  created: string;
  updated: string;
  position: number;
  reportDate: string;
  globalName?: string;
  globalId: string;
  value: any;
  fieldId: string;
  submissionId: string;
}

const schemaV1: RxJsonSchema<SubmissionGlobal> = {
  title: 'Submission Global',
  version: 0,
  description: 'Describes a Snapshot Submission Global',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    created: {
      type: 'string',
      default: '',
    },
    updated: {
      type: 'string',
      default: '',
    },
    reportDate: {
      type: 'string',
    },
    position: {
      type: 'number',
    },
    globalName: {
      type: ['string', 'null'],
    },
    globalId: {
      type: 'string',
    },
    value: {
      type: ['string', 'null'],
    },
    fieldId: {
      type: ['string', 'null'],
    },
    submissionId: {
      type: 'string',
    },
  },
  required: ['id', 'globalId', 'submissionId', 'reportDate'],
  indexes: ['submissionId', 'reportDate', 'globalId'],
};

let collection: RxCollection<SubmissionGlobal>;

const pullQueryBuilder = (syncDuration: number) => (doc) => {
  if (!doc) {
    // the first pull does not have a start-document
    doc = {
      position: 0,
      updated: dayjs().subtract(syncDuration, 'day').toISOString(),
    };
  }

  const query = `{
          submissionGlobalsForRxDB(
              lastPosition: "${doc.position}",
              minUpdated: "${doc.updated}"
              limit: 1500,
          ) {
            id
            created
            updated
            position
            reportDate
            globalName
            globalId
            value
            fieldId
            submissionId
            deleted
          }
      }`;

  return {
    query,
    variables: {},
  };
};

const submissionGlobalsModule: RxModule = {
  async init(db: RxDatabase) {
    collection = await db.collection({
      name: 'submissionglobals', // For some reason it doesn't like it being camel case submissionGlobals
      schema: schemaV1,
    });

    return collection;
  },
  initReplication(authToken: string, baseUrl: string, syncDuration: number) {
    const replicationState = collection.syncGraphQL({
      url: `${baseUrl}/graphql`, // url to the GraphQL endpoint
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      pull: {
        queryBuilder: pullQueryBuilder(syncDuration), // the queryBuilder from above
      },
      deletedFlag: 'deleted', // the flag which indicates if a pulled document is deleted
      live: true,
      liveInterval: 1000 * 60,
    });

    replicationState.error$.subscribe((error) => {
      console.error('RxDB: something went wrong syncing submission globals');
      console.error(error);
    });

    return replicationState;
  },
};

export default submissionGlobalsModule;
