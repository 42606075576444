import { getDatabase } from '@/rxdb/Database.service';

/* eslint-disable no-shadow */
const state = {
  versions: [],
  loading: false,
};

export const mutations = {
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  set(state, { versions }) {
    state.versions = versions;
  },
  reset(state) {
    state.versions = [];
    state.loading = false;
  },
};

export const actions = {
  async load({ state, dispatch, commit }, { submissionId }) {
    commit('setLoading', { loading: true });
    const db = await getDatabase();
    const submissionVersions = await db.submissionversions
      .find()
      .where('submissionId')
      .eq(submissionId)
      .exec();
    commit('set', { versions: Object.freeze(submissionVersions.map((v) => v.toJSON())) });
    commit('setLoading', { loading: false });
  },
  reset({ commit }) {
    commit('reset');
  },
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
